<template >
  <div id="dialogTip">
    <el-dialog :visible.sync="centerDialogVisible" width="30%" center @close="$emit('close')">
      <span slot="title" class="dialog-footer">
        <img src="@/assets/common/DialogTip/material-error.svg" alt="" />
      </span>
      <span>{{ text }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="DialogTipBtn" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      centerDialogVisible: true,
    }
  },
}
</script>
<style lang="scss">
#dialogTip {
  .el-dialog {
    min-width: 300px !important;
    max-width: 400px !important;
  }
  .el-dialog__body {
    color: #828282;
    text-align: center;
    letter-spacing: 1.5px;
    font-size: 1rem;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .DialogTipBtn {
      background: transparent linear-gradient(180deg, #438dff 0%, #438dff 100%) 0% 0% no-repeat
        padding-box;
      border: 1px solid #438dff;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
