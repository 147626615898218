<template >
  <div id="StorePredict">
    <div class="blueTitle">预测彩种：</div>
    <div class="dataRow" v-for="(it, key) in Object.keys(selectData)" :key="key">
      <div class="labelBlock">
        {{ selectData[it].title }}
      </div>
      <el-select v-model="selectData[it].selectValue" placeholder="请选择">
        <el-option
          v-for="(item, key) in selectData[it].options()"
          :key="key"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <div class="countDownBlock" v-if="key === 2 && countDownSec !== null">
        <div class="countDownTitle"> {{ countDownLabel }}</div>
        <div class="countDownTip"> ※注意～开奖前十分钟将会关闭预测 </div>
      </div>
    </div>

    <template v-if="selectLotData">
      <div class="blueTitle">预测奖号：</div>
      <div class="blueContent">
        <div class="dataRow gameRuleInfo">{{ selectLotData.remark }} </div>
        <div class="dataRow">
          <el-button class="resetBtn" type="primary" @click="clearAllChooseCode">重置</el-button>
          <el-input
            class="predictOpenCodeInput"
            v-model="predictOpenCodeInput"
            placeholder="可直接输入数字，数字间用英文逗号隔开"
          ></el-input>
          <el-button
            class="predictOpenCodeInputBtn"
            type="success"
            @click="predictOpenCodeByInputHandler"
            >确认</el-button
          >
        </div>
        <div class="chooseCodeBlock" v-for="(it, rowIndex) in codeRowArr" :key="it">
          <div class="chooseCodeTitle"> {{ it }}</div>
          <div class="chooseCodeContent">
            <div
              v-for="(num, colIndex) in (selectLotData.max >> 0) - (selectLotData.min >> 0) + 1"
              :key="num"
              :class="codeClassJudgement(rowIndex, (selectLotData.min >> 0) + colIndex)"
              @click="codeClickHandler(rowIndex, (selectLotData.min >> 0) + colIndex)"
            >
              {{ (selectLotData.min >> 0) + colIndex }}
            </div>
          </div>
        </div>
      </div>

      <div class="blueTitle" style="padding-bottom: 0px">预测彩种：</div>
      <div class="predictJsonBlock">
        <div class="blackTitle"> 填写范例说明： </div>
        <div class="dataRow oneRow" v-for="(it, index) in this.jsonPredictCode.length" :key="index">
          <div class="dataRow predictTitleBlock">
            <div>
              <el-input
                v-model="jsonPredictCode[index].titleText"
                placeholder="最多10個字"
                :disabled="index === 0"
              ></el-input>
            </div>
          </div>
          <div class="dataRow">
            <div class="leftItem"> 预测内容： </div>
            <el-input
              v-model="jsonPredictCode[index].contentText"
              placeholder="最多10個字"
              :disabled="index === 0"
            ></el-input>
          </div>
        </div>
        <div
          class="dataRow newPlayMethod"
          v-if="this.jsonPredictCode.length < MAX_JSON_PREDICT_ROW"
        >
          <div class="plusBtn" @click="plusBtnHandler">
            <i class="el-icon-plus"></i>
          </div>
          <div class="commentBlock" style="width: auto; margin-left: 5px">
            （新增玩法）※最多十项
          </div>
        </div>
      </div>
    </template>

    <el-button type="primary" @click="submitHandler">确认发号</el-button>

    <DialogTip
      v-if="dialogTipData.visible"
      :text="dialogTipData.text"
      @close="dialogTipData.visible = false"
    >
    </DialogTip>
  </div>
</template>

<script>
import DialogTip from '@/components/common/DialogTip'
const MAX_JSON_PREDICT_ROW = 11

export default {
  name: 'StorePredict',
  props: {
    shopInfo: {
      type: Object,
    },
  },
  components: {
    DialogTip,
  },
  data() {
    return {
      selectData: {
        type: { title: '彩种分类', options: () => this.typeOptions, selectValue: null },
        name: { title: '彩票名称', options: () => this.lotNameOptions, selectValue: null },
        issue: { title: '預測期數', options: () => this.issuesOptions, selectValue: null },
      },
      predictData: [],
      predictIssues: [],
      predictOpenCodeInput: '',
      countDownSec: null,
      countDownEvent: null,
      syncEvent: null,
      chooseCode: [],
      jsonPredictCode: [],
      dialogTipData: {
        visible: false,
        text: '',
      },
      // 離開獎10分鐘內時,不能去讓使用者去預測
      CONST_CANT_PREDICT_COUNT_DOWN_SEC: 600,
      isDebug: false,
    }
  },
  async created() {
    this.$store.commit('setLoading', true)
    await this.$store.dispatch('shop/getLotteryTypes').catch(this.$autoCatch)
    await this.$store
      .dispatch('shop/getLotteries')
      .then((res) => {
        this.predictData = res.data.filter((it) => it.predict_state === '1')
      })
      .catch(this.$autoCatch)
    this.$store.commit('setLoading', false)

    this.callSyncPredictOnline()
    this.countDownEvent = setInterval(this.countDownCallBack, 1000)
    this.syncEvent = setInterval(this.callSyncPredictOnline, 30000)

    // 測試模擬多筆資料
    // let i = 0
    // setInterval(() => {
    //   this.test(i++)
    // }, 500)
  },
  beforeDestroy() {
    clearInterval(this.countDownEvent)
    clearInterval(this.syncEvent)
  },
  computed: {
    MAX_JSON_PREDICT_ROW() {
      return MAX_JSON_PREDICT_ROW
    },
    predictTypeSort() {
      return this.predictData.reduce((sum, it, index) => {
        if (sum[it.type] === undefined) {
          sum[it.type] = []
        }
        sum[it.type].push(it)
        return sum
      }, {})
    },
    typeOptions() {
      return Object.keys(this.predictTypeSort)
        .map((it) => ({ label: it, value: it }))
        .sort((a, b) => {
          return (
            this.$store.state.shop.lottery_types_sort.indexOf(a.value) -
            this.$store.state.shop.lottery_types_sort.indexOf(b.value)
          )
        })
    },
    lotNameOptions() {
      if (
        Object.keys(this.predictTypeSort).length === 0 ||
        this.selectData.type.selectValue === null
      ) {
        return []
      } else {
        return this.predictTypeSort[this.selectData.type.selectValue].map((it) => ({
          label: it.lotteryname,
          value: it.lotterycode,
        }))
      }
    },
    issuesOptions() {
      return this.predictIssues.map((it) => ({ label: it.issue, value: it.issue }))
    },
    countDownLabel() {
      const days = Math.floor(this.countDownSec / 86400)
      const hours = Math.floor((this.countDownSec % 86400) / 3600)
      const minutes = Math.floor(((this.countDownSec % 86400) % 3600) / 60)
      const seconds = Math.floor(((this.countDownSec % 86400) % 3600) % 60)
      const fixZero = (num) => (num < 10 ? '0' + num : num)
      return `开奖时间尚馀：${days}天 又 ${fixZero(hours)} : ${fixZero(minutes)} : ${fixZero(
        seconds
      )}`
    },
    selectLotData() {
      // 若為true直接模擬選完 select的狀態
      if (this.isDebug) {
        return {
          lotteryid: '1',
          lotterycode: 'CQSSC',
          lotteryname: '欢乐生肖',
          status: '1',
          frequency: 'low',
          type: '时时分分彩',
          type_spell: 'ssff',
          predict_state: '1',
          max: '9',
          min: '0',
          duplicate: '1',
          quantity: '5',
          label: '万位,千位,百位,十位,个位',
          remark: '欢乐生肖：從0~9的10個數字中依照順序任選5個數字',
        }
      }
      // console.log(
      //   'this.predictData.find((it) => it.lotterycode === this.selectData.name.selectValue):',
      //   JSON.stringify(
      //     this.predictData.find((it) => it.lotterycode === this.selectData.name.selectValue)
      //   )
      // )
      else {
        return this.predictData.find((it) => it.lotterycode === this.selectData.name.selectValue)
      }
    },
    codeRowArr() {
      if (this.selectLotData) {
        return this.selectLotData.label.split(',')
      } else {
        return []
      }
    },
  },
  watch: {
    'selectData.type.selectValue'(newValue) {
      this.selectData.name.selectValue = null
      this.selectData.issue.selectValue = null
    },
    'selectData.name.selectValue'(newValue) {
      if (newValue === null) {
        return
      }
      this.clearAllPredictData()
      this.getLotteryIssuesAPI(newValue)
    },
    'selectData.issue.selectValue'(newValue) {
      if (newValue === null) {
        this.countDownSec = null
      }
      if (this.selectData.issue.selectValue !== null) {
        const issueData = this.predictIssues.find(
          (it) => it.issue === this.selectData.issue.selectValue
        )
        this.countDownSec = Math.floor((Date.parse(issueData.opendate) - Date.now()) / 1000)
      }
    },
  },
  methods: {
    callSyncPredictOnline() {
      this.$store.dispatch('shop/syncPredictOnline').catch(this.$autoCatch)
    },
    resetJsonPredictCode() {
      this.jsonPredictCode.length = 0
      this.jsonPredictCode = new Array(2).fill().map((it, index) => {
        const obj = this.generateJsonPredictCodeStruct()
        if (index === 0) {
          obj.titleText = '和数投注：'
          obj.contentText = '15'
        }
        return obj
      })
    },
    getLotteryIssuesAPI(selectLotCode, reChooseTip = false) {
      this.$store.commit('setLoading', true)
      this.$store
        .dispatch('shop/getLotteryIssues', selectLotCode)
        .then((res) => {
          // console.log('r:', res.data)
          // res.data = [
          //   { issue: '20220209001', curIssue: 1, opendate: '2022-02-09 10:20:00' },
          //   { issue: '20220209001', curIssue: 1, opendate: '2022-02-09 10:44:00' },
          //   { issue: '20220209002', curIssue: 2, opendate: '2022-02-09 10:45:00' },
          //   { issue: '20220209003', curIssue: 3, opendate: '2022-02-09 10:48:00' },
          //   { issue: '20220209004', curIssue: 3, opendate: '2022-02-09 11:00:00' },
          //   { issue: '20220209005', curIssue: 3, opendate: '2022-02-09 11:10:00' },
          //   { issue: '20220209006', curIssue: 3, opendate: '2022-02-09 11:15:00' },
          // ]

          //* status '0000' 或是 非'0000' 時,data都有可能是空值
          //* 因此.then和.catch都需要去檢查data是否為空
          if (res?.data.length === 0) {
            this.predictIssuesEmptyHandler()
          } else {
            this.predictIssues = res.data
              .filter((it) => {
                const countDownSec = Math.floor((Date.parse(it.opendate) - Date.now()) / 1000)
                if (countDownSec < this.CONST_CANT_PREDICT_COUNT_DOWN_SEC) {
                  return false
                } else {
                  return true
                }
              })
              .filter((it, index) => index <= 0)
          }
        })
        .catch((err) => {
          //* 福彩3D status是"0000" 所以不會進來
          if (err?.data.length === 0) {
            this.predictIssuesEmptyHandler()
          }
        })
        .finally((event) => {
          if (reChooseTip) {
            this.showDialogTip('奖号不到十分钟即将开奖 请选择其他期数或彩种预测')
          }
          this.$store.commit('setLoading', false)
        })
    },
    countDownCallBack() {
      if (this.countDownSec !== null) {
        if (--this.countDownSec < this.CONST_CANT_PREDICT_COUNT_DOWN_SEC) {
          this.selectData.issue.selectValue = null
          this.getLotteryIssuesAPI(this.selectData.name.selectValue, true)
        }
      }
    },
    codeClassJudgement(rowIndex, chooseNum) {
      if (this.chooseCode[rowIndex] === chooseNum) {
        return 'selectCode'
      } else {
        return 'code'
      }
    },
    codeClickHandler(rowIndex, chooseNum) {
      if (this.chooseCode[rowIndex] === chooseNum) {
        // 取消選取
        this.chooseCode[rowIndex] = undefined
      } else {
        this.chooseCode[rowIndex] = chooseNum
      }
      this.chooseCode = this.chooseCode.slice()
    },
    clearChooseCodeData() {
      const totalCodeNum = this.codeRowArr.length
      this.chooseCode.length = 0
      this.chooseCode = new Array(totalCodeNum).fill(undefined)
      this.chooseCode = this.chooseCode.slice()
    },
    clearAllChooseCode() {
      this.clearChooseCodeData()
      this.predictOpenCodeInput = ''
    },
    plusBtnHandler() {
      this.jsonPredictCode.push(this.generateJsonPredictCodeStruct())
    },
    generateJsonPredictCodeStruct() {
      return {
        titleText: '',
        contentText: '',
      }
    },
    submitHandler() {
      if (this.chooseCode.includes(undefined)) {
        this.showDialogTip(`最少需要输入${this.codeRowArr.length}个号码`)
      } else {
        const chooseCodeFilter = this.chooseCode.filter(
          (it, index) => index < this.codeRowArr.length
        )
        // 檢查號碼是否重複
        const hasRepeatItem = new Set(chooseCodeFilter).size !== chooseCodeFilter.length
        if (this.selectLotData.duplicate === '0' && hasRepeatItem) {
          this.showDialogTip('输入号码不可重复')
        } else {
          if (this.selectData.issue.selectValue === null) {
            this.showDialogTip('尚未选择期数')
          } else {
            let isTipJsonEmptyMessage = false
            this.jsonPredictCode.forEach((it) => {
              if (
                (it.titleText !== '' && it.contentText === '') ||
                (it.titleText === '' && it.contentText !== '')
              ) {
                isTipJsonEmptyMessage = true
              }
            })
            if (isTipJsonEmptyMessage) {
              this.$MSG.warn('请依照范例输入资料')
              return
            }
            const postData = {
              id: this.shopInfo.id,
              lotterycode: this.selectLotData.lotterycode,
              issue: this.selectData.issue.selectValue,
              predict_code: chooseCodeFilter.join(','),
              remark: JSON.stringify(
                this.jsonPredictCode
                  .filter((it, index) => index !== 0)
                  .filter((it) => it.titleText !== '')
              ),
            }
            this.$store.commit('setLoading', true)
            this.$store
              .dispatch('shop/predictLot', postData)
              .then(() => {
                this.showDialogTip('预测发表成功')
                this.clearAllPredictData()
              })
              .catch(this.$autoCatch)
              .finally(() => {
                this.$store.commit('setLoading', false)
              })
          }
        }
      }
    },
    test(i) {
      const postData = {
        id: this.shopInfo.id,
        lotterycode: 'CQSSC',
        issue: (20220222101 + i).toString(),
        predict_code: '0,1,2,3,4',
        remark: JSON.stringify(
          this.jsonPredictCode
            .filter((it, index) => index !== 0)
            .filter((it) => it.titleText !== '')
        ),
      }
      this.$store
        .dispatch('shop/predictLot', postData)
        .then(() => {
          this.showDialogTip('预测发表成功')
          this.clearAllPredictData()
        })
        .catch(this.$autoCatch)
    },
    predictIssuesEmptyHandler() {
      this.predictIssues.length = 0
      this.predictIssues = this.predictIssues.slice()
      this.$MSG.info('选择彩种没有期数资料,请稍后再试')
    },
    predictOpenCodeByInputHandler() {
      this.clearChooseCodeData()
      if (this.predictOpenCodeInput !== '') {
        const codeArr = this.predictOpenCodeInput.split(',')
        const isAllNumber = codeArr.every((it) => !isNaN(Number(it)))
        if (isAllNumber && this.predictOpenCodeInput.indexOf(',') !== -1) {
          codeArr.forEach((element, index) => {
            if (element !== '') {
              this.chooseCode[index] = parseInt(element.trim())
              this.chooseCode = this.chooseCode.slice()
            }
          })
        } else {
          this.$MSG.error('解析输入错误,请确保数字间用英文逗号隔开')
        }
      } else {
        this.$MSG.info('尚未输入')
      }
    },
    showDialogTip(message) {
      this.dialogTipData.visible = true
      this.dialogTipData.text = message
    },
    clearAllPredictData() {
      this.clearAllChooseCode()
      this.predictOpenCodeInput = ''
      this.resetJsonPredictCode()
      this.selectData.issue.selectValue = null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/views/Member/Store/Store.scss';
#StorePredict {
  .dataRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: 1.25rem;

    .labelBlock {
      width: 170px;
    }
    .countDownBlock {
      margin-left: 15px;
      font-size: 0.8rem;
      .countDownTip {
        color: $blueContentColor;
      }
      .countDownTitle {
        color: #ff5757;
      }
    }
    .resetBtn {
      margin-right: 38px;
      margin-top: 1.25rem;
    }
    .el-input {
      width: 290px;
      margin-top: 1.25rem;
    }
    .plusBtn {
      background: #d7d7d7 0% 0% no-repeat padding-box;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-weight: bold;
        color: white;
        font-size: 22px;
      }
    }
    .commentBlock {
      color: #87b3f9;
      font-size: 0.812rem;
      line-height: 1rem;
      width: 280px;
      padding: 7px 0;
    }
    .predictOpenCodeInput {
      margin-right: 10px;
    }
    .predictOpenCodeInputBtn {
      margin-top: 1.25rem;
    }
  }
  .gameRuleInfo {
    color: $blueContentColor;
    margin-bottom: 0;
  }
  .chooseCodeBlock {
    background: #f3f9ff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    border: 1px solid #b4c7d9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    $titleWidth: 100px;
    &:last-child {
      margin-bottom: 0px;
    }
    .chooseCodeTitle {
      width: $titleWidth;
    }
    .chooseCodeContent {
      width: calc(100% - #{$titleWidth});
      display: flex;
      flex-wrap: wrap;
      line-height: 40px;
      .code,
      .selectCode {
        width: 40px;
        height: 40px;
        background: #b4c7d9;
        border-radius: 50%;
        color: white;
        font-size: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 7px;
        transition: all 200ms ease;
        cursor: pointer;
        &:hover {
          background: #ffa114;
        }
      }
      .code {
        background: #b4c7d9;
      }
      .selectCode {
        background: #ffa114;
      }
    }
  }
  .predictJsonBlock {
    padding: 0px 15px;
    .dataRow {
      margin-bottom: 0px;
    }
    .oneRow {
      margin-bottom: 15px;
    }
    .predictTitleBlock {
      margin-right: 30px;
    }
    .el-input,
    .leftItem {
      margin-top: 10px;
    }
    .leftItem {
      width: 80px;
    }
    .el-input {
      width: 180px;
      margin-bottom: 0;
    }
    .newPlayMethod {
      margin-top: 1.25rem;
    }
  }
}
</style>
